<app-header [imageLinkable]="false"></app-header>

<div class="bolt-container pb-10">
  <!-- moved from admin-page -->
  <app-notification-banner [notificationMsg]="successMsg" *ngIf="isSuccess" type="success"></app-notification-banner>

  <div class="pt-4" id="no-data-notification" *ngIf="isError">
    <bolt-notification type="error">
      {{errorMsg}}
    </bolt-notification>
  </div>

  <div class="bolt-row pt-5 pb-5">
    <div class="bolt-col">
      <span class="font-size-xl heading">Smart home device recommendation tool</span>
    </div>
  </div>
  <div class="bolt-row pb-5">
    <div class="bolt-col">
      <span>You can view and edit the number of free devices remaining and cost per device for different
        partners.</span>
    </div>
  </div>
  <!-- moved from admin-page -->
  <div class="container-bar">
    <p *ngIf="!isError" class="d-inline date-text">Last updated: {{ lastUpdateTs | date: 'MM/dd/yyyy' }}</p>
    <bolt-button *ngIf="!isError" size="sm" iconright="download" (click)="exportToExcel()"
      class="p-0 d-inline download-button">
      Download table
    </bolt-button>
  </div>
  <bolt-waiting-indicator *ngIf="isLoading">Loading...</bolt-waiting-indicator>
  <div class="bolt-row m-auto" *ngIf="!isLoading">
    <div class="bolt-col p-0">

      <div class="no-data" *ngIf="isError" id="no-data-message">
        <p class="no-data-text">Unable to retrieve data at this time. Please try again.</p>
      </div>

      <div id="data-table" class="bolt-table bolt-table-vibrant-blue bolt-table-align-left">
        <table #table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource"
          (matSortChange)="sortData($event)" matSort>
          <ng-container matColumnDef="state">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea-state">State
            </th>
            <td mat-cell *matCellDef="let element" class="bolt-table-align-left textWrap width-textArea-state">
              {{element?.state}}
            </td>
          </ng-container>
          <ng-container matColumnDef="policyType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef
              class="table-header-cell width-textArea text-indent-deviceType1">
              Policy Type</th>
            <td mat-cell *matCellDef="let element" class="bolt-table-align-left width-textArea textWrap">
              {{element.policyType}}
            </td>
          </ng-container>
          <ng-container matColumnDef="coverageALimit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea-coverage">
              Coverage A Below
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea-coverage textWrap">{{element.coverageALimit |
              currency:'USD': 'symbol':'1.0-0' }}</td>
          </ng-container>
          <ng-container matColumnDef="deviceType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef
              class="table-header-cell width-textArea text-indent-deviceType1">Device Type 1
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea text-indent-deviceType1 textWrap">
              {{element.deviceType}}
            </td>
          </ng-container>
          <ng-container matColumnDef="vendor">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea">Device Vendor
              1
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea textWrap">{{element.vendor}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceOffer">
            <th mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef
              class="table-header-cell width-textArea-deviceOffer">
              Device Offer 1</th>
            <td mat-cell *matCellDef="let element" class="width-textArea-deviceOffer textWrap">{{element.deviceOffer}}
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceSavingsPct">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea">Device
              Savings 1
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea textWrap">
              {{formatPercentage(element.deviceSavingsPct)}}
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceType2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea">Device Type 2
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea textWrap">{{element.deviceType2}}</td>
          </ng-container>
          <ng-container matColumnDef="vendor2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea">Device Vendor
              2
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea textWrap">{{element.vendor2}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceOffer2">
            <th mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef
              class="table-header-cell width-textArea-deviceOffer">
              Device Offer 2</th>
            <td mat-cell *matCellDef="let element" class="width-textArea-deviceOffer textWrap">{{element.deviceOffer2}}
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceSavingsPct2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell width-textArea">Device
              Savings 2
            </th>
            <td mat-cell *matCellDef="let element" class="width-textArea textWrap">
              {{formatPercentage(element.deviceSavingsPct2)}}</td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef class="table-header-cell width-textArea">Edit</th>
            <td mat-cell *matCellDef="let element" class="bolt-table-align-left width-textArea textWrap">
              <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-icon">
                <mat-icon class="icon-view">more_horiz</mat-icon>
              </button>
              <mat-menu #appMenu="matMenu" [overlapTrigger]="false" class="option-container">
                <button mat-menu-item (click)="onEditClick(element)">Edit</button>
                <button mat-menu-item (click)="openDuplicateModal(element)">Duplicate</button>
                <button mat-menu-item (click)="openDeleteModel(element)">Delete</button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="paginator-section" id="paginator" [hidden]="isLoading">
    <div class="pageSizeContainer">
      <p class="pageSizeLabel">Items per page</p>
      <button [ngClass]="is25PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setFirstPageSize()">25</button>
      <button [ngClass]="is50PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setSecondPageSize()">50</button>
      <button [ngClass]="is100PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setThirdPageSize()">100</button>
    </div>
    <div class="bolt-col-md-auto">
      <mat-paginator paginatorPageNumberButtonDirective [recordsLength]="totalRecords" [length]="totalRecords"
        [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="paginationChange($event)">
      </mat-paginator>
    </div>
    <div>
      <div class="goToPageText">Go to page</div>
      <input type="number" class="goToInput" matInput [(ngModel)]="goTo" [value]="goTo">
      <bolt-button size="sm" (click)="goToChange()">Go</bolt-button>
    </div>
  </div>
</div>
<bolt-modal id="deleteModal" #deleteModalRef type="question" heading="Are you sure you want to delete this device?"
  primarybutton="Delete Device" secondarybutton="Cancel" (bolt-modal-primary-button-click)="deleteDevice()"
  (bolt-modal-secondary-button-click)="cancelDelete()">
  <p>Deleting the device will delete the device and it’s all data from the database. You cannot undo this action.</p>
</bolt-modal>

<bolt-modal id="duplicateModal" #duplicateModalRef type="question"
  heading="Are you sure you want to duplicate this row?">
  <p>Duplicating the row will not create a new device in the database, you will need to edit the details, review and
    save the
    duplicated device to create a new item.</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" (click)="editDuplicateRow()">Duplicate Row</bolt-button>
    <bolt-button slot="cancel" onclick="window.duplicateModal.closeModal()">Close</bolt-button>
  </bolt-button-bar>
</bolt-modal>