import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.less']
})
export class NotificationBannerComponent {

  @Input() notificationMsg: string = '';
  @Input() type: string = '';
}