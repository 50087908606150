import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceRecommendationsService {

  baseUrl: string;

  constructor(private http: HttpClient,
    private api: ApiService
  ) {
    // TODO: In future PR, adjust this code to use the device.service function getDeviceRecommendations instead of using the old style of API call.
    this.baseUrl = 'https://api-int-dev.nwie.net/policy-management/smarthome-experience-enrollments/v1/';
  }

  async getDeviceRecommendationApi(): Promise<any> {
    return await lastValueFrom(this.api.apigeeGet('device-recommendations/search/devices')).catch((err) => { throw err });
  }

  async deleteDeviceRecommendationApi(recommendationId: string): Promise<any> {
    return await lastValueFrom(this.api.apigeeDelete(`device-recommendations/${recommendationId}`)).catch((err) => { throw err });
  }

  async postDeviceRecommendationApi(recommendationData: any): Promise<any> {
    return await lastValueFrom(this.api.apigeePost('device-recommendations', recommendationData)).catch((err) => { throw err });
  }

  async saveDeviceRecommendationDetails(recommendationData: any): Promise<any | undefined> {
    let deviceRecommendations: any = [];
    await this.postDeviceRecommendationApi(recommendationData).then(async (response: any) => {
      deviceRecommendations = response?._embedded?.deviceRecommendations;
    });
    return deviceRecommendations;
  }

  async getDeviceRecommendationDetails(): Promise<any | undefined> {
    let deviceRecommendations: any = [];
    await this.getDeviceRecommendationApi().then(async (response: any) => {
      deviceRecommendations = response?._embedded?.deviceRecommendations;
    });
    return deviceRecommendations;
  }

  async deleteDeviceRecmmendationDetails(recommendationId: string): Promise<any | undefined> {
    let deviceRecommendations: any = [];
    await this.deleteDeviceRecommendationApi(recommendationId).then(async (response: any) => {
      deviceRecommendations = response
    });
    return deviceRecommendations;
  }

  async getDeviceRecommendations(policyNumber: string): Promise<any | undefined> {
    return await this.processRequest(
      `policies/${policyNumber}/devices`,
      (response: any) => {
        console.log('getDeviceRecommendations service call succeeded');
        return response;
      },
      (response: any) => {
        console.log('getDeviceRecommendations service call failed');
        return response;
      },
    );
  }

  async processRequest(path: string, onSuccess: (response: any) => {}, onError: (error: any) => {}): Promise<any | undefined> {
    const options = {
      headers: new HttpHeaders({}),
      params: { 'action': 'DoTheThing' }
    };

    return lastValueFrom(this.http
      .get(this.baseUrl + path, options))
      .then(onSuccess)
      .catch(onError);
  }
}
