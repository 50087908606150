import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, Optional, Output, Renderer2, Self, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { map, startWith } from 'rxjs';

@Directive({
  selector: '[paginatorPageNumberButtonDirective]'
})
export class PaginatorPageNumberButtonDirective implements AfterViewInit {

  @Output() emitpageChange: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() pageSize: number = 0;
  @Input() showFirstButton = true;
  @Input() showLastButton = true;
  @Input() renderButtonsNumber = 3;
  @Input() recordsLength: number = 0;
  @Input() hideDefaultArrows = false;
  private gapTxtEndRef!: HTMLElement;
  private gapTxtStartRef!: HTMLElement;
  private buttonContainerRef!: HTMLElement;
  private buttonsRef: HTMLElement[] = [];
  @Input() pageIndex: number = 0;

  constructor(
    @Host() @Self() @Optional() public readonly matPag: MatPaginator,
    private elementRef: ElementRef,
    private ren: Renderer2
  ) { }

  ngAfterViewInit(): void {
    this.paginationContainer();
    this.createBubbleDivRef();
    this.renderButtonNodes();
    this.matPag.pageIndex = (this.pageIndex || 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes?.['recordsLength'] && changes['recordsLength'].previousValue !== undefined && !changes['recordsLength'].isFirstChange()) || (changes['pageSize'] && changes["pageSize"].previousValue !== undefined)) {
      this.removeButtonNodes();
      this.switchPage(0);
      this.renderButtonNodes();
    }
    if (changes['pageIndex'] && changes["pageIndex"].previousValue !== undefined) {
      this.switchPage(changes['pageIndex'].currentValue);
      this.showActiveButton(changes['pageIndex'].previousValue, changes['pageIndex'].currentValue);
    }
  }

  private renderButtonNodes(): void {
    this.buildButtons();

    this.matPag.page
      .pipe(
        map((e) => [e.previousPageIndex ?? 0, e.pageIndex]),
        startWith([0, 0])
      )
      .subscribe(([prev, curr]) => {
        this.showActiveButton(prev, curr);
      });
  }

  private showActiveButton(previousIndex: number, newIndex: number) {
    const previouslyActive = this.buttonsRef[previousIndex];
    const currentActive = this.buttonsRef[newIndex];

    this.ren.removeClass(previouslyActive, 'button-node__active');

    this.ren.addClass(currentActive, 'button-node__active');

    this.buttonsRef.forEach((button) =>
      this.ren.setStyle(button, 'display', 'none')
    );

    const renderElements = this.renderButtonsNumber;
    const endDots = newIndex < this.buttonsRef.length - renderElements - 1;
    const startDots = newIndex - renderElements > 0;

    const firstButton = this.buttonsRef[0];
    const lastButton = this.buttonsRef[this.buttonsRef.length - 1];

    if (this.showLastButton) {
      this.ren.setStyle(this.gapTxtEndRef, 'display', endDots ? 'block' : 'none');
      this.ren.setStyle(lastButton, 'display', endDots ? 'flex' : 'none');
    }

    if (this.showFirstButton) {
      this.ren.setStyle(
        this.gapTxtStartRef,
        'display',
        startDots ? 'block' : 'none'
      );
      this.showFirstButton = false;
      this.ren.setStyle(firstButton, 'display', startDots ? 'flex' : 'none');
    }

    const startingIndex = startDots ? newIndex - renderElements : 0;

    const endingIndex = endDots
      ? newIndex + renderElements
      : this.buttonsRef.length - 1;

    for (let i = startingIndex; i <= endingIndex; i++) {
      const button = this.buttonsRef[i];
      this.ren.setStyle(button, 'display', 'flex');
    }
  }

  private paginationContainer() {
    const nativeElement = this.elementRef.nativeElement;
    const itemsPerPage = nativeElement.querySelector(
      '.mat-mdc-paginator-page-size'
    );
    const rangeLabel = nativeElement.querySelector(
      '.mat-mdc-paginator-range-label'
    );
    const previousButton = nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-previous'
    );
    const nextButtonDefault = nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    );

    this.ren.setStyle(itemsPerPage, 'display', 'none');
    this.ren.setStyle(rangeLabel, 'display', 'none');

    if (this.hideDefaultArrows) {
      this.ren.setStyle(previousButton, 'display', 'none');
      this.ren.setStyle(nextButtonDefault, 'display', 'none');
    }
  }

  private createBubbleDivRef(): void {
    const actionContainer = this.elementRef.nativeElement.querySelector(
      'div.mat-mdc-paginator-range-actions'
    );
    const nextButtonDefault = this.elementRef.nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    );

    this.buttonContainerRef = this.ren.createElement('div') as HTMLElement;
    this.ren.addClass(this.buttonContainerRef, 'button-node-container');

    this.ren.insertBefore(
      actionContainer,
      this.buttonContainerRef,
      nextButtonDefault
    );
  }

  private buildButtons(): void {
    const requiredButtons = Math.ceil(
      this.recordsLength / this.matPag.pageSize
    );

    if (requiredButtons === 1) {
      this.ren.setStyle(this.elementRef.nativeElement, 'display', 'block');
      return;
    }

    this.buttonsRef = [this.createButtonNodes(0)];

    this.gapTxtStartRef = this.createPageGapTxtElement();

    for (let index = 1; index < requiredButtons - 1; index++) {
      this.buttonsRef = [...this.buttonsRef, this.createButtonNodes(index)];
    }

    this.gapTxtEndRef = this.createPageGapTxtElement();

    this.buttonsRef = [
      ...this.buttonsRef,
      this.createButtonNodes(requiredButtons - 1),
    ];
  }

  private removeButtonNodes(): void {
    this.buttonsRef.forEach((button) => {
      this.ren.removeChild(this.buttonContainerRef, button);
    });

    this.buttonsRef.length = 0;
  }


  private createButtonNodes(i: number): HTMLElement {
    const buttonNode = this.ren.createElement('span');
    const text = this.ren.createText(String(i + 1));

    this.ren.addClass(buttonNode, 'button-node');
    this.ren.setStyle(buttonNode, 'margin-right', '8px');
    this.ren.appendChild(buttonNode, text);

    this.ren.listen(buttonNode, 'click', () => {
      this.switchPage(i);
    });

    this.ren.appendChild(this.buttonContainerRef, buttonNode);
    this.ren.setStyle(buttonNode, 'display', 'none');

    return buttonNode;
  }


  private createPageGapTxtElement(): HTMLElement {
    const gapEl = this.ren.createElement('span');
    const gapText = this.ren.createText('...');
    const firstButton = this.buttonsRef[0];

    this.ren.setStyle(gapEl, 'font-size', '18px');
    this.ren.setStyle(gapEl, 'margin-right', '8px');
    this.ren.setStyle(gapEl, 'padding-top', '6px');
    this.ren.setStyle(gapEl, 'color', '#919191');

    this.ren.appendChild(gapEl, gapText);

    this.ren.appendChild(this.buttonContainerRef, gapEl);

    this.ren.setStyle(gapEl, 'display', 'none');

    return gapEl;
  }

  /* To switch page */
  public switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag['_emitPageEvent'](previousPageIndex);
    this.emitpageChange.emit(i);
  }
}
